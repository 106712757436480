import React, { useState, useEffect } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { validateEmail } from '../../config/helpers'

import Text from '../Inputs/Text'
import Checkbox from '../Inputs/Checkbox'
import Button from '../Button'

import './style.scss'

const MailchimpForm = ({
}) => {
    const [ progress, setProgress ] = useState('none')
    const [ feedback, setFeedback ] = useState('')

	const [ email, setEmail ] = useState('')
	const [ validEmail, setValidEmail ] = useState(false)

	const [ agreeTerms, setAgreeTerms ] = useState(false)
	const [ validAgreeTerms, setValidAgreeTerms ] = useState(false)

    useEffect(() => {
		setValidEmail(progress === 'none' || validateEmail(email))
		setValidAgreeTerms(progress === 'none' || agreeTerms)
	}, [ email, agreeTerms, progress ])

    const handleSubmit = (e) => {
		e.preventDefault()
		setProgress('pending')

        setValidEmail(progress === 'none' || validateEmail(email))
		setValidAgreeTerms(progress === 'none' || agreeTerms)

		if (validEmail && agreeTerms) {
			addToMailchimp(email, {
				EMAIL: email,
			})
			.then((data) => {
				const result = data?.result

                if (data?.msg) {
                    setFeedback(data.msg)
                }

				if (result === 'error' && !data?.msg?.includes('already subscribed')) {
					setProgress('error')
				} else if (result === 'success' || data?.msg?.includes('already subscribed')) {
					setProgress('success')
				}
			})
			.catch((e) => {
				setProgress('error')
			})
		}
	}

    return (
        <form className="w-full" onSubmit={handleSubmit}>
            {progress !== 'success' && (
                <>
                    <Text
                        {...{
                            name: 'email',
                            placeholder: 'Email',
                            value: email,
                            required: true,
                            className: 'w-full py-2 mb-4 md:mb-0 text-base text-white placeholder-white',
                            changeHandler: ({ currentTarget: { value }}) => setEmail(value)
                        }}
                    />
                    {!validEmail && (
                        <p className="mt-4 text-red font-normal">*Enter valid email</p>	
                    )}
                    <Checkbox
                        {...{
                            checked: agreeTerms,
                            name: 'agree_terms',
                            label: <span>I opt in to the nDreams newsletter</span>,
                            className: 'mt-4 md:mt-8 font-normal text-boulder',
                            changeHandler: () => setAgreeTerms(!agreeTerms),
                        }}
                    />
                    {!validAgreeTerms && (
                        <p className="mt-4 text-red font-normal">*You have not accepted our terms and conditions</p>	
                    )}
                    <Button clickHandler={() => true} type="submit" className="mt-4 md:mt-8 px-12 hover:bg-secondary hover:border-secondary">Submit</Button>
                </>
            )}
            {feedback && (
                <p className="feedback mt-4 text-white font-normal" dangerouslySetInnerHTML={{ __html: feedback }} />
            )}
        </form>
    )
}

export default MailchimpForm
