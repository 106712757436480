import { graphql, useStaticQuery } from "gatsby"
import React, { createRef, useEffect, useMemo, useState, useRef } from "react"

import { ContextProvider, useMenuState } from "../../context"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { HiChevronDown, HiChevronRight } from "react-icons/hi"

import logo from "../../images/logo-white.svg"

import Link from "../Link"
import { Sidedrawer } from "./Sidedrawer"
import cx from "classnames"
import NavDropDown from "../NavDropdown"

import { gsap } from "gsap"
import WhatWeOfferNavDropDown from "../WhatWeOfferNavDropdown"

const StudioNavigation = ({ defaultImage, studiosObj }) => {
  const [keyArt, setKeyArt] = useState() // nDreams will be default

  const defaultNDreamsImage = getImage(defaultImage.gatsbyImageData)

  const handleKeyArt = (event) => {
    let studio = event.currentTarget.dataset.studio

    if (studio !== keyArt && studio !== undefined) {
      setKeyArt(studio)
    }
  }

  const handleDefaultKeyArt = () => setKeyArt(null)

  return (
    <div className="grid grid-cols-12 col-start gap-6 mx-auto lg:gap-10 container items-center">
      <div className="grid grid-cols-12 col-span-8 gap-10">
        {Object.entries(studiosObj).map((studio, index) => {
          return (
            <div
              key={index}
              data-studio={studio[0]}
              onMouseOver={handleKeyArt}
              onMouseOut={handleDefaultKeyArt}
              className={cx(
                "col-span-4 flex items-center flex-col justify-center h-28 group filter brightness-50  hover:brightness-100 grayscale hover:filter-none",
                {
                  "col-start-2": index === 0 || index === 2,
                  "col-start-7": index === 1 || index === 3,
                }
              )}
            >
              <Link
                to={studio[1].link}
                external={studio[1].external}
                data-studio={studio[0]}
                newTab={false}
                className="z-50 h-fit flex items-center justify-end flex-col"
              >
                <div className="flex items-end justify-end h-full">
                  <GatsbyImage
                    data-studio={studio[0]}
                    image={studio[1].image}
                    alt="Studio"
                    objectPosition={"center"}
                    objectFit="contain"
                    className=""
                  />
                </div>
                <p
                  className="text-white text-center font-bold uppercase tracking-wider mt-4 text-lg group-hover:text-primary"
                  data-studio={studio[0]}
                >
                  Visit Site
                </p>
              </Link>
            </div>
          )
        })}
      </div>

      <div className="col-span-6 lg:col-span-4 image">
        <GatsbyImage
          image={keyArt ? studiosObj[keyArt].key_art : defaultNDreamsImage}
          alt="something"
        />
      </div>
    </div>
  )
}

const Header = ({ color = "Transparent" }) => {
  const [scrollTop, setScrollTop] = useState(0)
  const { menuOpen, setMenuOpen } = useMenuState()
  const studiosRef = useRef(null)

  const {
    datoCmsGlobalNav: {
      studio,
      elevation,
      orbital,
      nearlight,
      studioKeyArt,
      elevationKeyArt,
      orbitalKeyArt,
      nearlightKeyArt,
      defaultImage,
    },
  } = useStaticQuery(graphql`
    query studioLogos {
      datoCmsGlobalNav {
        elevation: elevationLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        elevationKeyArt: elevationHoverImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        studio: farnboroughLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        studioKeyArt: farnboroughHoverImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        orbital: orbitalLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        orbitalKeyArt: orbitalHoverImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        nearlight: nearlightLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        nearlightKeyArt: nearlightHoverImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        defaultImage: defaultPlaceholderImage {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const studiosObj = {
    studio: {
      link: "https://www.studio.ndreams.com",
      external: true,
      image: getImage(studio.gatsbyImageData),
      key_art: getImage(studioKeyArt.gatsbyImageData),
    },
    elevation: {
      link: "https://www.elevation.ndreams.com",
      external: true,
      image: getImage(elevation.gatsbyImageData),
      key_art: getImage(elevationKeyArt.gatsbyImageData),
    },
    orbital: {
      link: "https://www.orbital.ndreams.com",
      external: true,
      image: getImage(orbital.gatsbyImageData),
      key_art: getImage(orbitalKeyArt.gatsbyImageData),
    },
    nearlight: {
      link: "https://nearlight.ndreams.com/",
      external: true,
      image: getImage(nearlight.gatsbyImageData),
      key_art: getImage(nearlightKeyArt.gatsbyImageData),
    },
  }

  const handleDropdownOpen = () => {
    gsap.to(studiosRef.current, {
      onStart: () => {
        gsap.to(studiosRef.current, {
          display: "block",
        })
      },
      opacity: 1,
      duration: 0.2,
    })
  }

  const handleDropdownClose = (event) => {
    const isLeaving = studiosRef.current.contains(event.relatedTarget)
    if (!isLeaving) {
      gsap.to(studiosRef.current, {
        onComplete: () => {
          gsap.to(studiosRef.current, {
            display: "none",
          })
        },
        opacity: 0,
        duration: 0.2,
      })
    }
  }

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return (
    <>
      <header
        className={`w-full fixed top-0 left-0 z-50 transition-all delay-400 transparent bg-black shadow-xl py-6 lg:py-0`}
      >
        <div className="container mx-auto px-10">
          <div className="grid grid-cols-2 lg:grid-cols-14 text-right">
            <div className="flex items-center lg:col-span-7 xl:col-span-6 xl:col-start-2 relative z-20">
              <Link to="/" className="hover:opacity-50 transition duration-200">
                <img src={logo} alt="nDreams" className="block w-30" />
              </Link>
            </div>
            <nav
              className={`flex items-center justify-center lg:block col-span-7 xl:col-span-6 w-full lg:w-auto h-full lg:h-auto fixed lg:relative top-0 left-0 lg:inset-auto bg-black lg:bg-transparent z-10 opacity-0 invisible pointer-events-none lg:opacity-100 lg:visible lg:pointer-events-auto`}
            >
              <ul className="flex flex-col lg:flex-row items-center justify-end text-lg text-white">
                <li>
                  <Link
                    to="https://ndreams.com/games"
                    newTab={false}
                    className="block py-6 px-6 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case hover:text-secondary transition duration-200"
                  >
                    Games
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://ndreams.com/news"
                    newTab={false}
                    className="block py-6 px-6 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case hover:text-secondary transition duration-200"
                  >
                    News
                  </Link>
                </li>
                <li
                  className="relative group cursor-pointer hover:text-secondary"
                  onPointerEnter={handleDropdownOpen}
                  onPointerLeave={(event) => handleDropdownClose(event)}
                >
                  <p className="py-6 px-6 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case transition duration-200 flex flex-row flex-nowrap items-center justify-center">
                    Studios{" "}
                    <HiChevronDown className="ml-2 text-xl transition-all transform group-hover:rotate-180" />
                  </p>
                  <div
                    className={`fixed left-0 top-20 opacity-0 hidden -mt-1 w-full bg-blue-dark z-50 p-4 py-8`}
                    ref={studiosRef}
                  >
                    <StudioNavigation {...{ studiosObj, defaultImage }} />
                  </div>
                </li>
                <WhatWeOfferNavDropDown name="What We Offer" />
                <NavDropDown name="Careers" />
              </ul>
            </nav>
            <div className="flex items-center justify-end col-span-1 relative z-20 lg:hidden text-white">
              <div
                className="flex flex-col items-end justify-center cursor-pointer"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span
                  className={`block h-1 w-8 transition duration-200 transform ${
                    menuOpen ? "-translate-y-2 bg-transparent" : "bg-white"
                  }`}
                />
                <span className="block h-1 w-8 mt-1 bg-white" />
                <span
                  className={`block h-1 w-8 mt-1 transition duration-200 transform ${
                    menuOpen ? "translate-y-2 bg-transparent" : "bg-white"
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidedrawer studios={studiosObj} />
    </>
  )
}

export default Header
