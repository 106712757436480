import React, { Fragment } from 'react'

import { ContextProvider } from '../../context'

import Browser from '../Browser'
import Header from '../Header'
import Footer from '../Footer'
import Lightbox from '../Lightbox'
import CookieBanner from '../CookieBanner'

const Root = ({ headerColour, children, game }) => (
	<ContextProvider>
		<Fragment>
			<Browser>
				<Header color={headerColour} />
				<div className="pt-[68px] lg:pt-[76px]">
					{children}
				</div>
				<Lightbox />
				<CookieBanner />
				<Footer {...{ game }} />
			</Browser>
		</Fragment>
	</ContextProvider>
)

export default Root
