import React from 'react'

import { useBrowserState } from '../../context'

import './style.scss'

const Browser = ({
    children,
}) => {
    const { browser } = useBrowserState()

    return (
        <>
            {children}
            {(browser === 'ie') && (
                <div className="browser flex flex-col items-center justify-center w-screen h-screen fixed top-0 left-0 bg-bare text-center z-50 overflow-hidden" style={{ background: 'white' }}>
                    <div className="container mx-auto px-10">
                        <h1 className="line-styled-heading max-w-2xl mx-auto mb-8 md:mb-12 text-black text-4xl sm:text-5xl md:text-6xl font-bold uppercase">This browser is no longer supported.</h1>
                        <p className="text-xl">Please switch to a supported browser to continue using the website.</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default Browser
