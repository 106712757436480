import { Link } from "gatsby"
import { gsap } from "gsap"
import React, { useRef } from "react"
import { HiChevronDown } from "react-icons/hi"

const WhatWeOfferNavDropDown = ({ name }) => {
  const containerRef = useRef(null)

  const whatWeOfferLinks = (
    <>
      <Link
        to="/about"
        className="block py-4 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case hover:text-secondary transition duration-200 hover:bg-blue-light"
      >
        Development
      </Link>
      <Link
        to="/publishing"
        className="block py-4 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case hover:text-secondary transition duration-200 hover:bg-blue-light"
      >
        Publishing
      </Link>
    </>
  )

  const handleOpen = () => {
    gsap.to(containerRef.current, {
      onStart: () => {
        gsap.to(containerRef.current, {
          display: "block",
        })
      },
      opacity: 1,
      duration: 0.2,
    })
  }

  const handleClose = (event) => {
    const isLeaving = containerRef.current.contains(event.relatedTarget)

    if (!isLeaving) {
      gsap.to(containerRef.current, {
        onComplete: () => {
          gsap.to(containerRef.current, {
            display: "none",
          })
        },
        opacity: 0,
        duration: 0.2,
      })
    }
  }

  return (
    <li
      className="relative group cursor-pointer group whitespace-nowrap"
      onMouseEnter={handleOpen}
      onMouseLeave={(event) => handleClose(event)}
    >
      <p className="py-6 px-6 text-2xl lg:text-lg font-bold lg:font-normal uppercase lg:normal-case transition duration-200 flex flex-row flex-nowrap items-center justify-center group-hover:text-secondary">
        {name}
        <HiChevronDown className="ml-2 text-xl transition-all transform group-hover:rotate-180" />
      </p>
      <div
        className="absolute hidden opacity-0 translate-y-0 text-center w-[150%] min-w-[200px] bg-blue-dark z-50"
        ref={containerRef}
      >
        {whatWeOfferLinks}
      </div>
    </li>
  )
}

export default WhatWeOfferNavDropDown
