import React from 'react'

import { BrowserProvider, useBrowserState } from './BrowserProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { SearchProvider, useSearchState } from './SearchProvider'
import { LightboxProvider, useLightboxState } from './LightboxProvider'

const ContextProvider = ({
	children,
}) => (
	<BrowserProvider>
		<MenuProvider>
			<SearchProvider>
				<LightboxProvider>
					{ children }
				</LightboxProvider>
			</SearchProvider>
		</MenuProvider>
	</BrowserProvider>
)

export {
	ContextProvider,
	useBrowserState,
	useMenuState,
	useSearchState,
	useLightboxState,
}
