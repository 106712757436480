const trimString = ({ str, length = 200 }) => {
  return str.length > length ? `${str.substring(0, length)}...` : str
}

const mapCmsColour = (value) => {
  switch (value) {
    case "Pink":
      return "secondary"
    case "Teal":
      return "primary"
    case "Black":
      return "black"
    case "White":
      return "white"
    default:
      return "secondary"
  }
}

const getModelSlug = (name) => {
  switch (name) {
    case "Post":
      return "/news"
    case "Game":
      return "/games"
    case "Career":
      return "/careers"
    default:
      return ""
  }
}

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const processBlogPost = (str) => {
  const re = /&lt;youtube&gt;(.*?)&lt;\/youtube&gt;/g
  const videos = str.match(re)

  if (videos?.length > 0) {
    for (let i = 0; i < videos.length; i++) {
      const videoId = videos[i]
        .replace("&lt;youtube&gt;", "")
        .replace("&lt;/youtube&gt;", "")
        .replace("</span><span>", "")

      str = str.replace(
        videos[i],
        `
                <div class="w-full my-8 md:my-12 pt-9/16 relative">
                    <iframe
                        width="1700"
                        height="750"
                        src="https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0&playsinline=0"
                        class="w-full h-full absolute top-0 left-0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    >
                    </iframe>
                </div>
            `
      )
    }

    return str
  } else {
    return str
  }
}

export {
  trimString,
  mapCmsColour,
  getModelSlug,
  validateEmail,
  processBlogPost,
}
