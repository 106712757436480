import React from 'react'
import cx from 'classnames'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
    to,
    children,
    external = false,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    className,
    newTab = true
}) => {
    const handleClick = (e) => {
        if (typeof clickHandler === 'function') {
            clickHandler(e)
            return true
        }
    }

    if (external || typeof clickHandler === 'function') {
        return <a
            href={to}
            target={newTab ? '_target' : null}
            rel="noreferrer noopener"
            className={cx('cursor-pointer', className)}
            onClick={handleClick}
        >
            {children}
        </a>
    } else {
        return <GatsbyLink {...{ to, className: cx('cursor-pointer', className), onMouseEnter, onMouseLeave }}>{children}</GatsbyLink>
    }
}

export default Link
