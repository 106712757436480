import React from "react"
import cx from "classnames"

import Link from "../Link"
import Icon from "../Icon"

import "./style.scss"

const LinkWithIcon = ({
  color = "mi-dark",
  to,
  external = false,
  text,
  icon,
  clickHandler,
  className,
  iconClasses,
}) =>
  to ? (
    <Link
      {...{
        to,
        external,
        clickHandler,
        className: cx(
          "link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none",
          className,
          color
        ),
      }}
    >
      {text && <span>{text}</span>}
      {icon && (
        <div
          className={cx(
            "link-with-icon__circle inline-flex items-center justify-center relative select-none",
            { "ml-4 md:ml-8": text },
            iconClasses
          )}
        >
          <Icon name={icon} className={cx("link-with-icon__icon", icon)} />
        </div>
      )}
    </Link>
  ) : (
    <span
      {...{
        onClick: (e) => clickHandler(e),
        className: cx(
          "link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none",
          className,
          color
        ),
      }}
    >
      {text && <span>{text}</span>}
      {icon && (
        <div
          className={cx(
            "link-with-icon__circle inline-flex items-center justify-center relative",
            { "ml-4 md:ml-8": text },
            iconClasses
          )}
        >
          <Icon name={icon} className={cx("link-with-icon__icon", icon)} />
        </div>
      )}
    </span>
  )

export default LinkWithIcon
