import React from 'react'
import cx from 'classnames'

const Text = ({
	label,
	name,
	value,
	placeholder,
	placeholderClass,
	disabled = false,
	required = false,
	className,
	changeHandler,
}) => (
	<div className={cx('inline-flex text-semibold border-b border-dashed', className)}>
		{label && <span className="inline-flex pr-4 text-gallery" style={{ marginRight: 2 }}>{label}:</span>}
		<input
			{...{
				type: 'text',
				name,
				value,
				placeholder,
				disabled,
				required,
				className: cx('flex-1 bg-transparent outline-none', placeholderClass, { disabled }),
				onChange: (e) => changeHandler(e),
			}}
		/>
	</div>
)

export default Text
